<template>
  <div>
    <CModalExtended
      :title="Type==1 ? $t('label.VehiclesList') : $t('label.ListOfDrivers')"
      color="dark"
      size="xl"
      class="modal-extended"
      @update:show="closeModal()"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            size="lg"
            :fields="fields"
            :items="computedListHold"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #Status="{item}">
                <td class="text-center">
                  <CBadge :color="getBadge(item.Status)">
                    {{ $t('label.'+item.Status) }}
                  </CBadge>
                </td>
            </template>
            <template #Button="{item}">
                <td class="text-center">
                  <CInputCheckbox
                    custom
                    :checked="item.Checked"
                    class="checkbook-filter"
                    @update:checked="SelectItem(item, Type)"
                  />
                </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
        <CButton color="wipe" @click="closeModal()">
          <CIcon name="x" />&nbsp; {{$t('button.exit')}}
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    List: [],
    modalActive: false,
  };
}

function closeModal() {
  this.$emit('CloseModal');
}

function SelectItem(item, type) {
  this.$emit('SelectedInfo', item, type);
  this.closeModal();
}

function computedListHold() {
  return this.List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      Checked: false,
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
        : 'N/A',
    };
  });
}

function fields(){
  if (this.Type===1) {
    return [
      { key: 'Nro', label: '#', _style: 'width:1%;', filter: false, _classes: 'text-center',sorter: true },
      { label: this.$t('label.brand'), key: 'BrandName', _classes:'text-center' },
      { label: this.$t('label.type'), key: 'VehicleClassifName', _classes:'text-center' },   
      { label: this.$t('label.vehiclePlate'), key: 'LicensePlate', _classes:'text-center'},
      { key: 'TransaLogin', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
      { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
      { key: 'Button', label: '', _style: 'width:5%;', filter: false,sorter: false },
    ];
  } else {
    return [
      { key: 'Nro', label: '#', _style: 'width:1%;', filter: false, _classes: 'text-center',sorter: true },
      { key: 'DriverCi', label: 'CI', _classes:'text-center' },
      { key: 'DriverName', label: this.$t('label.driver'), _classes:'text-center' },   
      { key: 'TransaLogin', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
      { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
      { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
      { key: 'Button', label: '', _style: 'width:5%;', filter: false,sorter: false },
    ];
  }
}

export default {
  name: 'modal-vehicle-or-driver-list',
  props: { items: Array, Type: Number },
  data,
  mixins: [
    GeneralMixin, 
  ],
  methods: {
    closeModal,
    SelectItem,
  },
  computed: {
    computedListHold,
    fields,
  },
  watch: {
    items: function(NewVal) {
      if (NewVal.length !== 0) {
        this.modalActive = true;
        this.List = NewVal;
      }else{
        this.List = [];
        this.modalActive = false;
      }
    },
  },
  
};
</script>