import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { NumberFormater } from "@/_helpers/funciones";
import { minValue, maxValue } from "vuelidate/lib/validators";

export default (maquinaryIsRequired, maxPackagingQuantity = 9999) => {
    return {
        Form:{
            //Form Basic
            BasicData: {
                Observation: { onlyAlphanumeric5 },
                MachineId: maquinaryIsRequired ? {required} : {},
                TransactionDate: {required},
                DocumentComment: {},
                TpEirId: {},
                TpEirTransacId: {},
            },

            //Form For Packaging
            Packaging: {
                YardAreaId: { },
                VisitBillOfLadingPackagingId: { required },
                maxPackagingQuantity: { },
                VisitBillOfLadingId: { },
                VolumeCheck: {},
                Quantity: { 
                    minValue: minValue(1),
                    maxValue: maxValue(maxPackagingQuantity),
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                    required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
                },
                Volumen: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                },
                Length: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                },
                Width: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                },
                Height: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                },
                Weigth: { 
                    NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
                },

                //Seals 
                Seal: {
                    Seal1: {
                        Seal: { }
                    },
                    Seal2: {
                        Seal: { },
                    },
                    Seal3: {
                        Seal: { }
                    },
                    Seal4: {
                        Seal: { },
                    },
                },
            },

            //Form Logistic
            Logistic: {
                CarrierId: { },
                numberIdLetter: { },
                SearchDriver: { },
                DriverId: { },
                SearchPlate: { },
                VehicleId: { },
            },

            //Form Details
            DetailJson: {required(value){ return value.length > 0 }}
        },
        
    }
}