<template>
    <div>
        <CRow class="mb-3">
            <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                <CButton
                    color="edit"
                    class="mr-2"
                    v-c-tooltip="{
                        content: $t('label.return'),
                        placement: 'top',
                    }"
                    size="sm"
                    @click="Reset()"
                >
                    <CIcon name="cil-chevron-left-alt" />
                    
                </CButton>
                <h6 class="mt-2">
                    {{  `${ this.isEdit ? $t('label.editBlReception') : $t('label.newBlReception')}: ${this.ContainerItem.NroBl}`}} 
                </h6>
            </CCol>
        </CRow>
        
        <CRow class="mb-3">
            <CCol sm="12">
                <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
                    <CTab :title="$t('label.basicData')" :class="(this.$v.Form.BasicData.$anyError)? 'tab-error' : ''">
                        <BasicData
                            :Form="Form"
                            :ModuleForm="Form.BasicData"
                            :isEdit="isEdit"
                            :Tab="tabIndex==0"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            :VForm="$v.Form" 
                        />
                    </CTab>

                    <CTab :title="$t('label.packaging')" :class="(this.$v.Form.DetailJson.$anyError)? 'tab-error' : ''">
                        <Packaging
                            :Form="Form"
                            :ModuleForm="Form.Packaging"
                            :SealForm="Form.Packaging.Seal"
                            :isEdit="isEdit"
                            :Tab="tabIndex==1"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            @UpdatePackagingList="(event)=>UpdatePackagingList(event)"
                        />
                    </CTab>

                    <CTab :title="$t('label.logistics')">
                        <Logistic
                            :Form="Form"
                            :ModuleForm="Form.Logistic"
                            :isEdit="isEdit"
                            :Tab="tabIndex==2"
                            :ContainerItem = "ContainerItem"
                            :Active = "Active"
                            :VForm="$v.Form" 
                        />
                    </CTab>
                </CTabs>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
                <CButton
                    square
                    color="add"
                    class="d-flex align-items-center"
                    @click="Submit()"
                    :disabled="isSubmit"
                 >
                    <CIcon name="checkAlt"/>{{$t('button.accept')}}
                </CButton>
            </CCol>
        </CRow>
    </div>
    
</template>
<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import ModalMixin from '@/_mixins/modal';
    import General from '@/_mixins/general';
    import BlReceptionFormValidations from '@/_validations/yard-management/warehouse/blReceptionValidations';
    import CustomTabs from '@/components/tabs/CustomTabs';
    import CustomTab from '@/components/tabs/CustomTab';
    import Logistic from './tabs/logistic';
    import Packaging from './tabs/packaging';
    import BasicData from './tabs/basicData';
    
    //data
    function data() {
        return {
            //General
            isEdit: false,
            tabIndex: -1,
            isSubmit: false,
            maquinaryIsRequired: false,
            totalQuantity: 0,

            //Form
            Form:{
                //Form Basic
                BasicData: {
                    MachineId: '',
                    TransactionDate: '',
                    TpEirId: process.env.VUE_APP_RECEPTION_BL_ID_TP_EIR,
                    TpEirTransacId: '',
                    DocumentComment: '',
                    DocumentJson:'',
                    Observation: '',
                },

                //Form Packaging
                Packaging: {
                    YardAreaId: '',
                    VisitBillOfLadingId: '',
                    maxPackagingQuantity: '',
                    BlName: '',
                    VisitBillOfLadingPackagingId: '',
                    Quantity: 0,
                    Volumen: 0,
                    Length: 0,
                    Width: 0,
                    Height: 0,
                    VolumeCheck: true,
                    Weigth: 0,

                    //Seals 
                    Seal: {
                        Seal1: {
                            Seal: '',
                        },
                        Seal2: {
                            Seal: '',
                        },
                        Seal3: {
                            Seal: '',
                        },
                        Seal4: {
                            Seal: '',
                        }
                    },
                },

                //Form Logistic
                Logistic: {
                    CarrierId: '',
                    numberIdLetter: '',
                    SearchDriver: '',
                    DriverId: '',
                    SearchPlate: '',
                    VehicleId: '',
                },

                DetailJson: [],
            },
        };
    }

    //-----------------------   Method    ------------------------

    function UpdatePackagingList(event) {
        this.Form.DetailJson = event;
    }

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    function Reset(isclose = true) {
         //General
        this.isEdit = false;
        this.tabIndex = -1;

        //Form
        this.Form.BasicData.MachineId = '';
        this.Form.BasicData.TransactionDate = '';
        this.Form.BasicData.DocumentComment = '';
        this.Form.BasicData.DocumentJson = [];
        this.Form.BasicData.Observation = '';

        //Packaging Form
        this.Form.Packaging.YardAreaId = '';
        this.Form.Packaging.VisitBillOfLadingId = '';
        this.Form.Packaging.VisitBillOfLadingPackagingId = '';
        this.Form.Packaging.Quantity = 0;
        this.Form.DetailJson = [];
        this.Form.Packaging.Length = 0;
        this.Form.Packaging.Width = 0;
        this.Form.Packaging.Height = 0;
        this.Form.Packaging.Volumen = 0;
        this.Form.Packaging.Weigth = 0;
        this.Form.Packaging.VolumeCheck = true;

        this.Form.Packaging.Seal.Seal1.Seal = '';
        this.Form.Packaging.Seal.Seal2.Seal = '';
        this.Form.Packaging.Seal.Seal3.Seal = '';
        this.Form.Packaging.Seal.Seal4.Seal = '';

        //Logistic Form
        this.Form.Logistic.CarrierId = '';
        this.Form.Logistic.numberIdLetter = '';
        this.Form.Logistic.SearchDriver = '';
        this.Form.Logistic.DriverId = '';
        this.Form.Logistic.SearchPlate = '';
        this.Form.Logistic.VehicleId = '';

        //General Form
        this.isSubmit = false;
        this.$v.$reset();
        isclose && this.$emit("Close");
    }

    function Submit() {
        
        try {
            this.$store.state.yardManagement.loading = true;
            this.isSubmit = true;
            this.Validate = true;

            let totalQuantity = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Quantity, 0) ?? 0;
            let totalWeigth = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Weigth, 0)  ?? 0;
            let totalLength = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Length, 0)  ?? 0;
            let totalWidth = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Width, 0)  ?? 0;
            let totalHeight = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Height, 0)  ?? 0;
            let totalVolumen = this.Form.DetailJson.reduce((accumulator, currentValue) => accumulator + currentValue.Volumen, 0)  ?? 0;

            this.$v.Form.BasicData.$touch();
            this.$v.Form.Logistic.$touch();
            this.$v.Form.DetailJson.$touch();

            console.log(totalLength)
      

            if (this.$v.Form.BasicData.$error || this.$v.Form.Logistic.$error || this.$v.Form.DetailJson.$error) 
                throw this.$t('label.errorsPleaseCheck');

            if(this.Form.DetailJson.length === 0)
                throw `${this.$t('label.must')} ${this.$t('label.aggregate')} ${this.$t('label.atLeast')} ${this.$t('label.detail')}`;

            let BlCargoJson = {
                YardId: this.$store.state.yardManagement.yardData.YardId,
                MachineId: this.Form.BasicData.MachineId,
                TransactionDate: this.Form.BasicData.TransactionDate,
                TpEirTransacId: this.Form.BasicData.TpEirTransacId,
                CarrierId: this.Form.Logistic.CarrierId,
                DriverId: this.Form.Logistic.DriverId,
                VehicleId: this.Form.Logistic.VehicleId,
                ReceptionDocumentJson: this.Form.BasicData.DocumentJson,
                BlJson:[{
                    VisitBillOfLadingId: this.ContainerItem.VisitBillOfLadingId,
                    BlCargoId:  this.ContainerItem.BlCargoId ?? '',
                    Quantity: totalQuantity,
                    Weigth: totalWeigth,
                    Length: totalLength,
                    Width: totalWidth,
                    Height: totalHeight,
                    Volumen: totalVolumen,
                    DetailJson:this.Form.DetailJson
                }],
                Observation: this.Form.BasicData.Observation
            }

            this.$http.post('YardWCargoReception', BlCargoJson, { root: 'BlCargoJson' })
            .then((response) => {
                this.notifySuccess({text: response.data.data[0].Response});
                this.isSubmit = false;
                this.$emit("Update-list");
                this.Reset();
            }).catch((err) => {
                this.isSubmit = false;
                this.$store.state.yardManagement.loading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isSubmit = false;
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }

    //-----------------------   Computed   ------------------------

    function validPackaginJson(){
        return this.Form?.DetailJson?.some((e)=> ((e.YardAreaId && e.YardAreaId != '') && (e.Status != 0))) ?? false;
    }

    export default{
        name: 'bl-reception',
        data,
        mixins: [General, ModalMixin],
        directives: UpperCase,
        props: {
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            Active: Boolean,
        },
        components: {
            CustomTabs,
            CustomTab,
            Logistic,
            Packaging,
            BasicData
        },
        validations() {
            return BlReceptionFormValidations(this.validPackaginJson, this.Form.Packaging.maxPackagingQuantity);
        },
        methods: {
            handleTab,
            UpdatePackagingList,
            Reset,
            Submit,
        },
        computed: {
            validPackaginJson,
        },
        watch:{
            Active: function (val) {
                if (val) {
                    this.Reset(false);
                    this.tabIndex = 0;
                }
            }
        }
    }
</script>