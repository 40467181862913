<template>
    <div class="my-3">
        <div>
            <CRow>
                <CCol>
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.PackagingBlInformation')">
                            <CCard class="card-border">
                                <CRow class="px-3">
                                    <CCol sm="12" lg="3">
                                        <CSelect
                                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                            :label="$t('label.detail')"
                                            :options="packagingOptions"
                                            addLabelClasses="required text-right"
                                            v-model.trim="$v.Form.Packaging.VisitBillOfLadingPackagingId.$model"
                                            :is-valid="hasError($v.Form.Packaging.VisitBillOfLadingPackagingId)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.VisitBillOfLadingPackagingId)"
                                            size="sm"
                                            @blur="$v.Form.Packaging.VisitBillOfLadingPackagingId.$touch()"
                                            @change="changePackagingsValues"
                                        />
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CInput
                                            size="sm"
                                            :label="`${$t('label.bl')}`"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-3', input:'col-sm-8'}"
                                            v-uppercase
                                            v-model.trim="Form.Packaging.BlName"
                                            :disabled="true"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-4 col-form-label-sm mb-0 required">{{`${$t('label.quantity')}`}}</label>
                                            <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                                <money
                                                    size="sm"
                                                    v-bind="measure" 
                                                    :class="!$v.Form.Packaging.Quantity.$dirty ? 'form-control' : ($v.Form.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                    v-model="$v.Form.Packaging.Quantity.$model"
                                                    addLabelClasses="required text-right"
                                                    maxlength= "12"
                                                >
                                                </money>
                                                <div class="invalid-feedback" v-if="$v.Form.Packaging.Quantity.$error">
                                                    {{ errorMessage($v.Form.Packaging.Quantity) }}
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-3 col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                                            <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                                <money
                                                size="sm"
                                                v-bind="measure" 
                                                :class="!$v.Form.Packaging.Weigth.$dirty ? 'form-control' : ($v.Form.Packaging.Weigth.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                v-model="$v.Form.Packaging.Weigth.$model"
                                                addLabelClasses="required text-right"
                                                maxlength= "13"
                                                >
                                                </money>
                                                <div class="invalid-feedback" v-if="$v.Form.Packaging.Weigth.$error">
                                                {{ errorMessage($v.Form.Packaging.Weigth) }}
                                                </div>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CInput
                                            size="sm"
                                            :label="`${$t('label.Seal')} 1`"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                                            v-uppercase
                                            :is-valid="hasError($v.Form.Packaging.Seal.Seal1.Seal)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.Seal.Seal1.Seal)"
                                            v-model.trim="$v.Form.Packaging.Seal.Seal1.Seal.$model"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CInput
                                            size="sm"
                                            :label="`${$t('label.Seal')} 2`"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-3', input:'col-sm-8'}"
                                            v-uppercase
                                            :is-valid="hasError($v.Form.Packaging.Seal.Seal2.Seal)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.Seal.Seal2.Seal)"
                                            v-model.trim="$v.Form.Packaging.Seal.Seal2.Seal.$model"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CInput
                                            size="sm"
                                            :label="`${$t('label.Seal')} 3`"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-4', input:'col-sm-8'}"
                                            v-uppercase
                                            :is-valid="hasError($v.Form.Packaging.Seal.Seal3.Seal)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.Seal.Seal3.Seal)"
                                            v-model.trim="$v.Form.Packaging.Seal.Seal3.Seal.$model"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CInput
                                            size="sm"
                                            :label="`${$t('label.Seal')} 4`"
                                            addLabelClasses="text-right"
                                            :horizontal="{label:'col-sm-3', input:'col-sm-8'}"
                                            v-uppercase
                                            :is-valid="hasError($v.Form.Packaging.Seal.Seal4.Seal)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.Seal.Seal4.Seal)"
                                            v-model.trim="$v.Form.Packaging.Seal.Seal4.Seal.$model"
                                        >
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <div class="form-group form-row">
                                            <div class="input-group col-sm-12 col-lg-3 input-group-sm justify-content-end">
                                                <CSwitch
                                                    color="watch"
                                                    variant="3d"
                                                    type="checkbox"
                                                    :checked.sync="$v.Form.Packaging.VolumeCheck.$model"
                                                    @change.native="(event)=> changeVolumeCheck(event)"
                                                />
                                            </div>
                                            <label class="col-sm-12 col-lg-auto col-form-label-sm">{{`${$t('label.automaticCalculation')} (M³)`}}</label>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="3" class="pl-0">
                                        <div class="form-group form-row">
                                            <label class="text-right col-sm-12 col-lg-3 col-form-label-sm px-0 mx-1">{{`${$t('label.volume')} (M³)`}}</label>
                                            <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                                <money
                                                    v-if="!Form.Packaging.VolumeCheck"
                                                    size="sm"
                                                    v-bind="measure"
                                                    :class="!$v.Form.Packaging.Volumen.$dirty ? 'form-control' : ($v.Form.Packaging.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                    v-model="Form.Packaging.Volumen"
                                                    addLabelClasses="required text-right"
                                                    maxlength= "13"
                                                >
                                                </money>
                                                <money
                                                    v-else
                                                    size="sm"
                                                    v-bind="measure"
                                                    :class="!$v.Form.Packaging.Length.$dirty || !$v.Form.Packaging.Width.$dirty || !$v.Form.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                                                    v-model="Meters.Volumen"
                                                    addLabelClasses="required text-right"
                                                    maxlength= "13"
                                                    disabled
                                                >
                                                </money>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol  sm="12" lg="6">
                                        <CRow>
                                            <CCol sm="12" lg="4" class="px-0">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                        <money
                                                            size="sm"
                                                            v-bind="measure" 
                                                            :class="!$v.Form.Packaging.Length.$dirty ? 'form-control' : ($v.Form.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                            v-model="$v.Form.Packaging.Length.$model"
                                                            addLabelClasses="text-right"
                                                            maxlength= "13"
                                                            :disabled= "!Form.Packaging.VolumeCheck"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12" lg="4" class="px-0">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                        <money
                                                            size="sm"
                                                            v-bind="measure" 
                                                            :class="!$v.Form.Packaging.Width.$dirty ? 'form-control' : ($v.Form.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                            v-model="$v.Form.Packaging.Width.$model"
                                                            addLabelClasses="text-right"
                                                            maxlength= "13"
                                                            :disabled= "!Form.Packaging.VolumeCheck"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>
                                            <CCol sm="12" lg="4" class="px-0">
                                                <div class="form-group form-row">
                                                    <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                                                    <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                                        <money
                                                            size="sm"
                                                            v-bind="measure" 
                                                            :class="!$v.Form.Packaging.Height.$dirty ? 'form-control' : ($v.Form.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                                            v-model="$v.Form.Packaging.Height.$model"
                                                            addLabelClasses="text-right"
                                                            maxlength= "13"
                                                            :disabled= "!Form.Packaging.VolumeCheck"
                                                        >
                                                        </money>
                                                    </div>
                                                </div>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                    <CCol sm="12" lg="3">
                                        <CSelect
                                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                            :label="$t('label.location')"
                                            :options="ubicationOptions"
                                            addLabelClasses="text-right"
                                            v-model.trim="$v.Form.Packaging.YardAreaId.$model"
                                            :is-valid="hasError($v.Form.Packaging.YardAreaId)"
                                            :invalid-feedback="errorMessage($v.Form.Packaging.YardAreaId)"
                                            size="sm"
                                            @blur="$v.Form.Packaging.YardAreaId.$touch()"
                                        />
                                    </CCol>
                                </CRow> 
                            </CCard>
                        </CTab>
                    </CTabs>
                </CCol>

                <CCol sm="12">
                    <CCol class="d-flex justify-content-end col-form-label p-0 col-sm-12 col-lg-12 col-form-label-sm mb-2">
                    <CButton
                        color="add"
                        size="sm"
                        class="mr-1"
                        v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}"
                        @click="SubmitPackaging()"
                    >
                        <CIcon name="checkAlt"/>
                    </CButton>
                    <CButton
                        color="wipe"
                        class="mr-1"
                        size="sm"
                        v-c-tooltip="{content: $t('label.clearFilters'), placement: 'top-end'}"
                        @click="clearFilters()"
                    >
                        <CIcon name="cil-brush-alt" />
                    </CButton>
                    </CCol>
                </CCol>

                <CCol sm="12" lg="12" class="justify-content-center">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items-per-page="5"
                        :items="ComputedPackaging"
                        :fields="Packagingfields"
                        column-filter
                        pagination
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        sorter
                    >

                        <template #Status="{ item }">
                            <td class="text-center">
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                        
                        <template #options="{ item }">
                            <td class="text-center">
                                <CButton
                                    color="edit"
                                    square
                                    size="sm"
                                    class="mr-2"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.packaging'),
                                        placement: 'top-end'
                                        }"
                                    @click="EditPackaging(item)"
                                >
                                    <CIcon name="pencil" />
                                </CButton>
                                <CButton
                                    class="btn btn-sm btn-wipe"
                                    v-c-tooltip="{
                                        content: $t('label.delete')+$t('label.detail'),
                                        placement: 'top-end',
                                    }"
                                    @click="DeletePackaging(item)"
                                >
                                    <CIcon name="cil-trash" />
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                    <p v-if="PackagingJsonList.length === 0" class="mt-2 text-danger text-center required">
                        {{$t('label.must')+' '+$t('label.aggregate')+' '+$t('label.atLeast')+' 1 '+$t('label.detail')}}
                    </p>
                    <p class="text-right fw-bold">{{ `TOTAL: ${FormatNumber(PackagingJsonList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)}` }}</p>
                </CCol>
            </CRow>
        </div>
    </div>
</template>
<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    import BlReceptionFormValidations from '@/_validations/yard-management/warehouse/blReceptionValidations';
    
    //data
    function data() {
        return {
            //General
            isEditPackaging: false,
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 0,
                masked: false,
                maxlength: 18,
            },

            //Seals 
            ModalSealActive: false,
            SealJson: {},

            //List of selects
            UbicationList: [],
            PackagingList: [],
            billOfLandingList: [],
            PackagingJsonList: [],
            ConditionList: []
        };
    }

    //-----------------------   Method    ------------------------

    async function getPackagingList() {
        this.loadingOverlay = true;
        await this.$http
            .get('YardWVisitImportPackaging-by-VisitBillOfLading',{VisitBillOfLadingId: this.ContainerItem.VisitBillOfLadingId})
                .then(async response => {
                let data =  response?.data?.data  ? response.data.data : {};
                this.PackagingList = data;
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.loadingOverlay = false;
            });
    }

    async function getUbicationList() {
        this.loadingOverlay = true;
        await this.$http
            .get('YardWAreaModule-by-YardId',{YardId: this.$store.state.yardManagement.yardData.YardId})
                .then(async response => {
                let data =  response?.data?.data  ? response.data.data : {};
                this.UbicationList = data;
            })
            .catch( err => {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.loadingOverlay = false;
            });
    }

    async function getService() {
        if(this.PackagingList.length == 0 || this.UbicationList.length == 0){
            this.$store.state.yardManagement.loading = true;
            (this.PackagingList.length == 0) && await this.getPackagingList();
            (this.UbicationList.length == 0) && await this.getUbicationList();
            this.$store.state.yardManagement.loading = false;
        }
    }

    function clearFilters() {
        this.ModuleForm.YardAreaId = '';
        this.ModuleForm.VisitBillOfLadingPackagingId = '';
        this.ModuleForm.VisitBillOfLadingId = '';
        this.ModuleForm.Quantity = 0;

        this.ModuleForm.Length = 0;
        this.ModuleForm.Width = 0;
        this.ModuleForm.Height = 0;
        this.ModuleForm.Volumen = 0;
        this.ModuleForm.Weigth = 0;
        this.ModuleForm.VolumeCheck = true;

        //------------- SEAL ----------------
        this.SealForm.Seal1.Seal = '';
        this.SealForm.Seal2.Seal = '';
        this.SealForm.Seal3.Seal = '';
        this.SealForm.Seal4.Seal = '';

        this.isEditPackaging = false;
        setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);
    }

    function FormatNumber(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    function EditPackaging(item){
        this.isEditPackaging = true;
        this.ModuleForm.VisitBillOfLadingPackagingId = item.VisitBillOfLadingPackagingId;
        this.ModuleForm.YardAreaId = item.YardAreaId;
        this.ModuleForm.Quantity = NumberFormater.setNum(item.Quantity);

        this.ModuleForm.Length = item.Length,
        this.ModuleForm.Width = item.Width,
        this.ModuleForm.Height = item.Height,
        this.ModuleForm.Volumen = item.Volumen,
        this.ModuleForm.Weigth = item.Weigth,
        this.ModuleForm.VolumeCheck = item.VolumeCheck,
        
        this.Form.Packaging.Seal.Seal1.Seal = item.Seal1;
        this.Form.Packaging.Seal.Seal2.Seal = item.Seal2;
        this.Form.Packaging.Seal.Seal3.Seal = item.Seal3;
        this.Form.Packaging.Seal.Seal4.Seal = item.Seal4;
        this.$v.Form.Packaging.$touch();
    }

    function DeletePackaging(item) {
        this.$swal
            .fire(this.alertProperties({
                text: `${this.$t('label.deleteQuestion')}?`,
            }))
        .then((result) => {
        if (result.isConfirmed) {
            if (item.VisitBillOfLadingPackagingId) {
                this.PackagingJsonList = this.PackagingJsonList.map(Packaging => {
                    if (Packaging.VisitBillOfLadingPackagingId == item.VisitBillOfLadingPackagingId) {
                    return {
                        ...Packaging,
                        Status: 0,
                    }
                    } else {
                    return Packaging;
                    }
                })
            
                this.$emit('UpdatePackagingList',this.PackagingJsonList)
            }else{
                this.PackagingJsonList = this.PackagingJsonList.filter(e => e.IdX != item.IdX);
                this.$emit('UpdatePackagingList',this.PackagingJsonList)
            }
            this.clearFilters();
        }
        });
    }

    function changePackagingsValues(event){
        let data = this.PackagingList.find((e)=>e.VisitBillOfLadingPackagingId == event?.target?.value) ?? []
        this.Form.Packaging.maxPackagingQuantity = data.QuantityStowage
    }
    
    function SubmitPackaging(){
        try {
            this.$v.Form.Packaging.$touch();
            
            if (this.$v.Form.Packaging.VisitBillOfLadingPackagingId.$error || this.$v.Form.Packaging.Quantity.$error || this.$v.Form.Packaging.YardAreaId.$error) 
                throw this.$t('label.errorsPleaseCheck');

            if (!this.isEditPackaging && this.PackagingJsonList.some(item => (item.VisitBillOfLadingPackagingId == this.ModuleForm.VisitBillOfLadingPackagingId) && item.Status != 0)) 
                throw this.$t('label.ItemPreviouslyRegistered');

            let packagingData =  this.PackagingList.find( (val) =>  (val.VisitBillOfLadingPackagingId == this.ModuleForm.VisitBillOfLadingPackagingId) && val.Status != 0);
            let UbicationData =  this.UbicationList.find( (val) =>  val.YardAreaId == this.ModuleForm.YardAreaId);
            
            if (this.isEditPackaging && this.PackagingJsonList.some(item => (item.VisitBillOfLadingPackagingId == this.ModuleForm.VisitBillOfLadingPackagingId) && item.Status != 0)) {
                this.isEditPackaging = false;
                
                this.PackagingJsonList = this.PackagingJsonList.map(item => {
                    if ((item.VisitBillOfLadingPackagingId == this.ModuleForm.VisitBillOfLadingPackagingId) && item.Status != 0) 
                        return {
                            ...item,
                            NroBl: this.ContainerItem.NroBl ?? 'N/A',
                            BlCargoMasterDetailId: '',
                            VisitBillOfLadingPackagingId: this.ModuleForm.VisitBillOfLadingPackagingId ?? '',
                            PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
                            PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
                            YardAreaId: this.ModuleForm.YardAreaId ?? '',
                            YardAreaName: UbicationData?.YardAreaName ?? 'N/A',
                            Seal1: this.Form.Packaging.Seal.Seal1.Seal,
                            Seal2: this.Form.Packaging.Seal.Seal2.Seal,
                            Seal3: this.Form.Packaging.Seal.Seal3.Seal,
                            Seal4: this.Form.Packaging.Seal.Seal4.Seal,
                            SerialJson: [],
                            Quantity: this.ModuleForm.Quantity ?  Number.parseInt(this.ModuleForm.Quantity) : 0,
                            Length: this.Form.Packaging.Length ?? 0,
                            Width: this.Form.Packaging.Width ?? 0,
                            Height: this.Form.Packaging.Height ?? 0,
                            Weigth: this.Form.Packaging.Weigth ?? 0,
                            Volumen: !this.Form.Packaging.VolumeCheck ? this.Form.Packaging.Volumen ?? 0 : this.Meters.Volumen,
                            VolumeCheck: this.Form.Packaging.VolumeCheck,
                            Status: 1,
                        }
                    else 
                        return item;
                })
            }

            else {

                this.PackagingJsonList.push({
                    IdX: this.PackagingJsonList.length > 0 ? this.PackagingJsonList[this.PackagingJsonList.length - 1].IdX + 1 : 1,
                    NroBl: this.ContainerItem.NroBl ?? 'N/A',
                    BlCargoMasterDetailId: '',
                    VisitBillOfLadingPackagingId: this.ModuleForm.VisitBillOfLadingPackagingId ?? '',
                    PackagingNameEs: packagingData?.PackagingNameEs ?? 'N/A',
                    PackagingNameEn: packagingData?.PackagingNameEn ?? 'N/A',
                    YardAreaId: this.ModuleForm.YardAreaId ?? '',
                    YardAreaName: UbicationData?.YardAreaName ?? 'N/A',
                    Seal1: this.Form.Packaging.Seal.Seal1.Seal,
                    Seal2: this.Form.Packaging.Seal.Seal2.Seal,
                    Seal3: this.Form.Packaging.Seal.Seal3.Seal,
                    Seal4: this.Form.Packaging.Seal.Seal4.Seal,
                    Quantity: this.ModuleForm.Quantity ? Number.parseInt(this.ModuleForm.Quantity) : 0,
                    SerialJson: [],
                    Length: this.Form.Packaging.Length ?? 0,
                    Width: this.Form.Packaging.Width ?? 0,
                    Height: this.Form.Packaging.Height ?? 0,
                    Weigth: this.Form.Packaging.Weigth ?? 0,
                    Volumen: !this.Form.Packaging.VolumeCheck ? this.Form.Packaging.Volumen ?? 0 : this.Meters.Volumen,
                    VolumeCheck: this.Form.Packaging.VolumeCheck,
                    Status: 1,
                });

            }
            this.$emit('UpdatePackagingList',this.PackagingJsonList)
            this.clearFilters();
        } 
        catch (error) {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: error,
            type: "error"
        });
        }
    }

    
    //-----------------------   Computed   ------------------------

    function Packagingfields(){
        return [
        { key: 'options', label: '', _style: 'min-width:90px; text-align:center;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
        { key: 'PackagingName', label: this.$t('label.packaging'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width:150px;', _classes:"center-cell text-center", sorter: true, filter: true},
        { key: 'VesselUbicationName', label: this.$t('label.location'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Length', label: this.$t('label.length'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Width', label: this.$t('label.width'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Height', label: this.$t('label.height'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Volumen', label: this.$t('label.volume'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Weigth', label: this.$t('label.weight'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'Seals', label: this.$t('label.Seals'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        ];
    }

    function ComputedPackaging() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let List = this.PackagingJsonList.filter(item => item.Status !== 0)
        return List.map((item, index) => {
        return {
            ...item,
            Nro: index+1,
            PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
            VesselUbicationName: item.YardAreaName ?? 'N/A',
            Seals: ` ${(item.Seal1 && item.Seal1 != '')? ` S1: (${item.Seal1})`: ''}${(item.Seal1 && item.Seal1 != '')? `,`: ''} 
                     ${(item.Seal2 && item.Seal2 != '')? ` S2: (${item.Seal2})`: ''}${(item.Seal2 && item.Seal2 != '')? `,`: ''} 
                     ${(item.Seal3 && item.Seal3 != '')? ` S3: (${item.Seal3})`: ''}${(item.Seal3 && item.Seal3 != '')? `,`: ''} 
                     ${(item.Seal4 && item.Seal4 != '')? ` S4: (${item.Seal4})`: ''}
                   `,
            Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
        }
        });
    }

    function blOptions(){
        let chart = [{
            value: '', 
            label: this.$t('label.select'),
        }]

        this.billOfLandingList.map((e) => {
            chart.push({
                value: e.VisitBillOfLadingId, 
                label: e.NroBl
            });
        })

        return chart;
    }

    function ubicationOptions(){
        var chart = [{
        value: '',
        label: this.$t('label.select'),
        }]
        this.UbicationList.map((e) => {
        chart.push({
            value: e.YardAreaId,
            label: e.YardAreaName
        });
        })
        return chart;
    }

    function packagingOptions(){
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        var chart = [{
        value: '',
        label: this.$t('label.select'),
        }]
        this.PackagingList.map((e) => {
        chart.push({
            value: e.VisitBillOfLadingPackagingId,
            label: e[`PackagingName${_lang}`]
        });
        })
        return chart;
    }

    function Meters() {
        return {
            Volumen: NumberFormater.setNum( this.Form.Packaging.Length) * NumberFormater.setNum( this.Form.Packaging.Width) * NumberFormater.setNum( this.Form.Packaging.Height)
        }
    }

    function changeVolumeCheck() {
        this.Form.Packaging.Length = 0;
        this.Form.Packaging.Width = 0;
        this.Form.Packaging.Height = 0;
        this.Form.Packaging.Volumen = 0;
    }

    export default{
        name: 'bl-packaging',
        data,
        mixins: [General],
        directives: UpperCase,
        props: {
            Form: {
                type: Object,
                required: true,
                default: () => {},
            },
            ModuleForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            SealForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            isEdit: Boolean,
            Tab: Boolean,
            Active: Boolean,
        },
        components: {
        },
        validations() {
            return BlReceptionFormValidations(false, this.Form.Packaging.maxPackagingQuantity);
        },
        methods: {
            SubmitPackaging,
            clearFilters,
            FormatNumber,
            EditPackaging,
            DeletePackaging,
            getService,
            getPackagingList,
            getUbicationList,
            changePackagingsValues,
            changeVolumeCheck
        },
        computed: {
            Meters,
            blOptions,
            Packagingfields,
            ubicationOptions,
            packagingOptions,
            ComputedPackaging,
        },
        watch:{
            Tab: async function (Newval) {
                if (Newval == 1) {
                    this.Form.Packaging.BlName = this.ContainerItem.NroBl;
                    this.$v.Form.Packaging.$reset();
                    await this.getService();
                    
                }
            },
            Active: async function (Newval) {
                if (!Newval) {
                    this.isEditPackaging = false;
                    this.ModalSealActive = false;
                    this.SealJson = {};
                    this.PackagingJsonList = [];
                    this.UbicationList = [];
                    this.PackagingList = [];
                    this.billOfLandingList = [];
                    this.ConditionList = []
                    this.clearFilters();
                }
                else
                    setTimeout(() => { this.$v.Form.Packaging.$reset(); }, 10);
            }
        }
    }
</script>